"use strict"

import Swiper from 'swiper';
// import scrollTo from 'jquery.scrollto';
import {
    Navigation,
    Pagination,
    Zoom,
    Thumbs,
    Autoplay,
    Grid,
    EffectFade
} from 'swiper/modules';

function watchElement(elementId, code) {
    let element = $(elementId).get(0);
    if ($(elementId).length > 0) {
        let observer = new IntersectionObserver((entries) => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    code();
                    observer.unobserve(element)
                }
            }
        }, {
            root: null,
            threshold: .1,
        });
        observer.observe(element);
    }
}

watchElement('[x-swiper="6"]', function () {
    new Swiper('[x-swiper="6"]', {
        modules: [Pagination, Grid],
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
            el: ".swiper-pagination",
        },
        // loop: true,
        grid: {
            rows: $(window).width() >= 576 ? 1 : 2,
        },
        breakpoints: {
            576: {
                spaceBetween: 10,
                slidesPerView: 3,
            },
            768: {
                spaceBetween: 30,
                slidesPerView: 4,
            },
            992: {
                spaceBetween: 30,
                slidesPerView: 6,
            }
        }
    })
});

watchElement('[x-swiper="3"]', function () {
    new Swiper('[x-swiper="3"]', {
        modules: [Pagination],
        slidesPerView: 1.2,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
        },
        breakpoints: {
            767: {
                slidesPerView: 1.5,
            },
            991: {
                slidesPerView: 2,
            },
            1199: {
                slidesPerView: 2.5,
            },
            1399: {
                slidesPerView: 3,
            }
        }
    })
});

watchElement('[x-swiper="aangeraden"]', () => {
    new Swiper('[x-swiper="aangeraden"]', {
        slideClass: "product",
        slidesPerView: 1.3,
        spaceBetween: 30,
        speed: 500,
        breakpoints: {
            540: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            }
        },
    })
})

watchElement('[x-swiper="pvc"]', () => {
    new Swiper('[x-swiper="pvc"]', {
        modules: [Navigation, Pagination],
        slideClass: "product",
        slidesPerView: 1.3,
        spaceBetween: 30,
        speed: 500,
        navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
        },
        breakpoints: {
            540: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            }
        },
        pagination: {
            el: ".swiper-pagination",
        },
    });
})

watchElement('#before-footer', () => {
    $('#before-footer').css('background-image', 'url(https://www.tete.nl/wp-content/uploads/2023/07/netherlands1.svg)')
})

watchElement('[x-swiper="vergelijkbaar"]', () => {
    new Swiper('[x-swiper="vergelijkbaar"]', {
        slideClass: "product",
        slidesPerView: 1.3,
        spaceBetween: 30,
        speed: 1000,
        breakpoints: {
            540: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            }
        },
    });
})

$(document).on('click', '.pop-prod-filters div', e => {
    let me = $(e.target)
    let xSwiper = $('[x-swiper="' + me.attr('x-for') + '"]')
    $('.pop-prod-filters > div').removeClass('active')
    me.addClass('active')
    $('.pop-product-swipers > div').addClass('d-none')
    xSwiper.removeClass('d-none')
    if (xSwiper.find('.swiper-wrapper').children().length == 0) {
        $('.pop-product-swipers').addClass('loading').find('.bouncing-loader').removeClass('d-none')
        $.ajax({
            type: 'POST',
            url: ajax.ajaxurl,
            data: {
                action: 'getProducts',
                cat_term_id: me.attr('x-id')
            },
            success: function (response) {
                $('.pop-product-swipers').removeClass('loading').find('.bouncing-loader').addClass('d-none')
                $('[x-swiper="' + $(e.target).attr('x-for') + '"] .swiper-wrapper').append(response)

                $(() => {
                    new Swiper('[x-swiper="' + me.attr('x-for') + '"]', {
                        modules: [Navigation, Pagination],
                        slideClass: "product",
                        slidesPerView: 1.3,
                        spaceBetween: 30,
                        speed: 500,
                        breakpoints: {
                            540: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            }
                        },
                        pagination: {
                            el: ".swiper-pagination",
                        },
                        navigation: {
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        },
                    });
                })
            }
        });
    }
})

$(() => {
    if ($(window).width() < 991) {
        $('[id^="menu-footer-"]').css('display', 'none')
    }

    beforeHeaderSwiper();
})

if ($('main#product-page').length > 0) {
    const productSwiper = new Swiper(".productSlider", {
        modules: [Navigation, Zoom, Thumbs],
        zoom: true,
        loop: false,
        spaceBetween: 0,
        autoHeight: true,
        navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
        },
        thumbs: {
            swiper: new Swiper(".productThumbSlider", {
                loop: false,
                spaceBetween: 15,
                slidesPerView: 6,
                freeMode: true,
                watchSlidesProgress: true,
            }),
        },
    });

    $('.zoom-button').click(() => {
        productSwiper.zoom.toggle()
    })

    // $.ajax({
    //     type: 'POST',
    //     url: ajax.ajaxurl,
    //     data: {
    //         action: 'vergelijkbaarAlternatief',
    //         data: {
    //             product_id: document.body.className.match(/postid-(\d+)/)[1]
    //         }
    //     },
    //     success: function (response) {
    //         $('.pop-product-swipers').removeClass('loading')
    //             .find('.bouncing-loader').addClass('d-none')
    //         $('[x-swiper="vergelijkbaar"] .swiper-wrapper').append(response)
    //         $(() => {
    //             const swiper = new Swiper('[x-swiper="vergelijkbaar"]', {
    //                 slideClass: "product",
    //                 slidesPerView: 1.3,
    //                 spaceBetween: 30,
    //                 speed: 500,
    //                 breakpoints: {
    //                     540: {
    //                         slidesPerView: 2,
    //                     },
    //                     992: {
    //                         slidesPerView: 3,
    //                     },
    //                     1200: {
    //                         slidesPerView: 4,
    //                     }
    //                 },
    //             });
    //         })
    //     }
    // });
}



let openSidebars = 0;
$(document).on('click', '[cta="wacp"], [cta="menu"]', e => {
    e.preventDefault()
    if (992 >= $(window).width()) {
        const eTop = $('#header-mobile').outerHeight();
        const topVal = $('#header-mobile').position().top - $(window).scrollTop() + eTop
        $('#mega-menu-primary').css("top", topVal).css('height', 'calc(100svh - ' + topVal + 'px)')
        $('#yith-wacp-popup').css('top', topVal)
            .find('.yith-wacp-content').css('height', 'calc(100svh - ' + topVal + 'px)')
    }
})


// proxy yith cart button
$(document).on('click', '[cta="wacp"]', e => {
    $("#yith-wacp-popup").hasClass("open") ? closeSidebars() : (
        openSidebars > 0 && closeSidebars(),
        $("#yith-wacp-mini-cart").click(),
        openSidebars++
    );
})

$(document).on('click', '[cta="menu"]', e => {
    const me = $("#mega-menu-primary");
    me.hasClass("translate-0") ? closeSidebars() : (
        openSidebars > 0 && closeSidebars(),
        $("#mega-menu-primary").addClass("translate-0"),
        openSidebars++
    );
})

$(window).on('resize', () => {
    closeSidebars()
})

function closeSidebars() {
    openSidebars = 0
    $('.yith-wacp-close').trigger('click')
    $('#mega-menu-primary').removeClass('translate-0');
    $('.close-button').click()
}

$(window).scroll(function () {
    const header = $('#header-mobile');
    if ($(window).scrollTop() > 32) {
        header.css("position", "sticky");
    } else {
        header.css("position", "unset");
    }

    const eTop = $('#header-mobile').outerHeight();
    const topVal = $('#header-mobile').position().top - $(window).scrollTop() + eTop
    $('#yith-wacp-popup').css('top', topVal)

    992 >= $(window).width() && $('#mega-menu-primary').css("top", topVal).css('height', 'calc(100svh - ' + topVal + 'px)')
});

$('.yith-wacp-mini-cart-count').on('DOMSubtreeModified', () => {
    $('.cart-count').html($('.yith-wacp-mini-cart-count').html())
})
$('[cta="wacp"]').append('<p class="cart-count position-absolute end-0 top-0 rounded-pill d-flex justify-content-center align-items-center">' + $('.yith-wacp-mini-cart-count').text() + '</p>')


// $('#product-archive .description').ready(() => {
//     const me = $('#product-archive .description')
//     me.height() > 80 && (
//         me.addClass('closed'),
//         $('<a class="btn-tertiary cd-toggle mb-40px d-block" rel="nofollow">Lees meer</a>').insertAfter('#product-archive .description')
//     )

//     $('.cd-toggle').click(e => {
//         $(e.target).parent().find('.description').toggleClass(['closed', 'm-0'])
//         if ($(e.target).html() == 'Lees meer') $(e.target).html('Lees minder')
//         else $(e.target).html('Lees meer')
//     })
// })

$(".footer-col .widget-title-holder").click(e => {
    const parent = $(e.target).parent().parent()
    575 >= $(window).width() && (
        parent.find('.menu').slideToggle(),
        parent.find('.widget-title-holder').toggleClass('closed')
    )
})

$('#hero-home .btn-primary').on('click', e => {
    e.preventDefault()
    $(window).scrollTo($('#grid-categories'), {
        axis: 'y',
        duration: 800,
        offset: {
            top: -143,
            left: 0
        }
    })
})

$('.hero-clip-path').ready(() => {
    if ($(window).width() >= 992) {
        $('.hero-clip-path').css('background-image', 'url("http://www.tete.nl/wp-content/uploads/2023/07/hero.webp")')
    }
    setTimeout(() => {
        if ($(window).width() >= 992) {
            $('.hero-clip-path').css('opacity', '1')
        }
    }, 5e1);
})

function beforeHeaderSwiper() {
    new Swiper('#before-header .usps-swiper', {
        modules: [Autoplay, EffectFade],
        slideClass: "usp",
        slidesPerView: 1,
        loop: true,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
        },
        effect: "fade",
    });
}

jQuery(document).on('yith-wcan-ajax-filtered', function () {
    beforeHeaderSwiper()
});